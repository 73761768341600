/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification_bell': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M24.973 23.964l-1.368-2.059V11.138C23.605 6.644 19.97 3 15.487 3c-4.484 0-8.118 3.644-8.118 8.138v10.767L6 23.964h18.973zm.658-2.651l1.03 1.55c.617.927.367 2.18-.558 2.798a2.01 2.01 0 01-1.116.339H6.013a2.016 2.016 0 01-1.675-3.138l1.03-1.55V11.156C5.369 5.547 9.906 1 15.5 1S25.63 5.547 25.63 11.156v10.156zM13.192 29C12.534 29 12 28.552 12 28s.534-1 1.192-1h4.616c.658 0 1.192.448 1.192 1s-.534 1-1.192 1h-4.616z" _fill="#F9F9F9" fill-rule="nonzero"/>'
  }
})
